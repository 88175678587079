import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/images/Reel-Martini/logo.svg";
import SearchBar from '../components/SearchBar';

const Header = () => {
  const [activeItem, setActiveItem] = useState('');

  const handleItemClick = (itemName:any) => {
    if (activeItem === itemName) {
      setActiveItem(''); // If the same item is clicked again, remove the active class
    } else {
      setActiveItem(itemName); // Set the new active item
    }
  };

  return (
    <div className="header-banner-wrapper">
      <div className="topheader">
        <p>"Happiness is a <span>martini</span> and a lot of <span>drama</span>"</p>
      </div>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light navbar-hover fixed-top header">
          <div className="container">
            <Link className="logo-white" to={"/"}>
              <img loading="lazy" src={logo} className="img-fluid" alt="header-logo" />
            </Link>
            <Link className="logo-color d-none" to={"/"}>
              <img loading="lazy" src={logo} className="img-fluid" alt="header-logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarHover"
              aria-controls="navbarDD"
              aria-expanded="false"
              aria-label="Navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarHover">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item px-2">
                  <Link
                    className={`nav-link tx-AFAFAF fs-18 ${activeItem === 'tvshows' ? 'active' : ''}`}
                    to={"/tvshows"}
                    onClick={() => handleItemClick('tvshows')}
                  >
                    Tv Shows
                  </Link>
                </li>
                <li className="nav-item px-2">
                  <Link
                    className={`nav-link tx-AFAFAF fs-18 ${activeItem === 'movies' ? 'active' : ''}`}
                    to={"/movies"}
                    onClick={() => handleItemClick('movies')}
                  >
                    Movies
                  </Link>
                </li>
                <li className="nav-item px-2">
                  <Link
                    className={`nav-link tx-AFAFAF fs-18 ${activeItem === 'curatedcontent' ? 'active' : ''}`}
                    to={"/curatedcontent"}
                    onClick={() => handleItemClick('curatedcontent')}
                  >
                    Curated Content
                  </Link>
                </li>
                <li className="nav-item px-2">
                  <Link
                    className={`nav-link tx-AFAFAF fs-18 ${activeItem === 'review' ? 'active' : ''}`}
                    to={"/review"}
                    onClick={() => handleItemClick('review')}
                  >
                    Reviews
                  </Link>
                </li>
                <li className="nav-item px-2">
                  <Link
                    className={`nav-link tx-AFAFAF fs-18 ${activeItem === 'news' ? 'active' : ''}`}
                    to={"/news"}
                    onClick={() => handleItemClick('news')}
                  >
                    Latest News
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav ml-auto d-flex">
                <SearchBar />
                <li className="nav-item px-2 m-lg-auto">
                  <a className="nav-link search-icon" href="#">
                    <i className="fa fa-search"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div className="header-height-50"></div>
      <div className="sectional-padding-2"></div>
    </div>
  );
};

export default Header;
