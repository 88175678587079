import React, { useState } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import './SearchBar.css';

const SearchBar: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const apiKey = '901d089574f998daa421ef3edee1154f'; // Replace with your actual API key
  const movieApiUrl = 'https://api.themoviedb.org/3/search/movie';
  const tvApiUrl = 'https://api.themoviedb.org/3/search/tv';

  const handleSearch = async () => {
    try {
      if (searchTerm.trim() === '') {
        setSearchResults([]);
        return;
      }
  
      const movieSearchUrl = `${movieApiUrl}?include_adult=false&language=en-US&page=1&query=${encodeURIComponent(
        searchTerm
      )}&api_key=${apiKey}`;
  
      const tvSearchUrl = `${tvApiUrl}?include_adult=false&language=en-US&page=1&query=${encodeURIComponent(
        searchTerm
      )}&api_key=${apiKey}`;
  
      const fetchMovieData = fetch(movieSearchUrl).then((res) => res.json());
      const fetchTvData = fetch(tvSearchUrl).then((res) => res.json());
  
      const [movieData, tvData] = await Promise.all([fetchMovieData, fetchTvData]);
  
      const combinedResults = [
        ...(movieData.results.slice(0, 5) || []).map((result:any) => ({ ...result, isMovie: true })),
        ...(tvData.results.slice(0, 5) || []).map((result:any) => ({ ...result, isMovie: false })),
      ];
      setSearchResults(combinedResults);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  
    if (newSearchTerm.trim() === '') {
      setSearchResults([]); // Clear the search results when the search term is empty
    } else {
      handleSearch();
    }
  };
  const handleResultClick = (id: number, isMovie: boolean, title: string) => {
    if (isMovie) {
      window.location.href = `/#/detail/${id}`;
    } else {
      window.location.href = `/#/tvdetails/${id}`;
    }
    setSearchTerm(title); // Set the clicked value as the new search term
    setSearchResults([]); // Clear the search results
  };

  return (
    <div>
      <InputGroup className="search-bar">
        <FormControl
          placeholder="Search..."
          aria-label="Search"
          aria-describedby="basic-addon2"
          className="search-input"
          value={searchTerm}
          onChange={handleChange}
        />
      </InputGroup>
      {searchResults.length > 0 && (
        <ul className="search-results">
          {searchResults.map((result, index) => (
           <li
           key={index}
           onClick={() => handleResultClick(result.id, result.isMovie, result.title)}
         >
           {result.title || result.name}
         </li>
         
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
